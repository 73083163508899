import React from "react";
import "twin.macro";
import { PageProps, graphql } from "gatsby";
import { BlogWrapper } from "../components/pages/blog";
import { IBlogPost } from "../utils/types";
import { SEO } from "../components";
import { getImage } from "gatsby-plugin-image";

const BlogPost: React.FC<PageProps> = ({ data: { strapiBlogpost } }) => {
  const post: IBlogPost = {
    ...strapiBlogpost,
    contentHtml: strapiBlogpost.content.data.content,
    categoryName: strapiBlogpost.category.name,
    categorySlug: `/blog/${strapiBlogpost.category.slug}`,
    image: {
      ...strapiBlogpost.image,
      localFile: getImage(strapiBlogpost.image.localFile),
    },
  };
  return (
    <>
      <SEO title={post.title} description={post.description} />
      <section>
        <div tw="bg-sonic">
          <div tw="max-w-screen-xl mx-auto px-4 pb-12">
            <BlogWrapper post={post} />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostQuery($id: String!) {
    strapiBlogpost(id: { eq: $id }) {
      category {
        name
        slug
      }
      content {
        data {
          content
        }
      }
      createdAt
      description
      id
      publishTime
      publishedAt
      slug
      title
      updatedAt
      image {
        alternativeText
        caption
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 860, quality: 100)
          }
        }
      }
    }
  }
`;
